<template>
  <div>
    <b-modal
      id="modal-scoped_agentdetail"
      title="用户信息"
      centered
      :hide-footer="true"
      size="sm"
      button-size="sm"
    >
      <template>
        <p class="agentDetail">
          用户姓名：<span>{{ selectedUser.name == "" ? selectedUser.number : selectedUser.name}}</span>
        </p>
        <p class="agentDetail">
          用户账户：<span>{{ selectedUser.account ? selectedUser.account : selectedUser.number }}</span
          ><b-button
            size="sm"
            style="float: right; padding: 3px 8px; font-size: 11px"
            v-clipboard:copy="selectedUser.account ? selectedUser.account.toString() : selectedUser.number"
            >复制</b-button
          >
        </p>
        <p class="agentDetail">
          电话号码：<span>{{ selectedUser.telephone == null ? '': selectedUser.telephone}}</span
          ><b-button
            size="sm"
            style="float: right; padding: 3px 8px; font-size: 11px"
            v-clipboard:copy="selectedUser.telephone"
            >复制</b-button
          >
        </p>
        <!-- <p class="agentDetail">
          应用名称：<span>{{ organization }}</span>
        </p> -->
      </template>

      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()"> OK </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
export default {
  name: "AgentsInfo",
  props: ["selectedUser"],
  data() {
    return {
      organization: "",
    };
  },
  methods: {
    getUserInfo() {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/user_info`,
          method: "post",
          headers: {
            
          },
          data: {
            id: that.selectedUser.number
          },
        })
        .then((res) => {
          console.log(res);
          // if (res.data.code == 200) {
          //   that.organization = res.data.data.organization;
          // }
        });
    },
  },
  mounted: function () {
    // this.getUserInfo();
  },
};
</script>
<style scoped>
.agentDetail {
  color: #2c3e50;
  font-size: 16px;
}
</style>
